// Variable overrides

// Global Constants
$bs-sidebar-width: 14rem;

// Default Colors
$bs-background-color: #ffffff;
$bs-primary-blue-color: #2c4058;
$bs-hover-blue-color: #385271;
$bs-light-blue-color: #4d5e72;
$bs-dark-blue-color: #17293e;
$bs-white-color: #ffffff;
$bs-off-white-color: #f8f8f8;
$bs-extra-light-grey-color: #e8e8e8;
$bs-add-button-grey-color: #eaeaea;
$bs-dropdown-gray-color: #fbfbfb;
$bs-default-gray-color: #f2f2f2;
$bs-hover-gray-color: #dadbde;
$bs-light-grey-color: #c3c9d0;
$bs-dark-gray-color: #b9b9b9;
$bs-secondary-dark-gray-color: #9fa2ad;
$bs-secondary-extra-dark-gray-color: #73767f;
$bs-extra-dark-gray-color: #737680;
$bs-light-red-color: rgba(250, 85, 85, 0.2);
$bs-red-color: #fa5555;
$bs-light-red-color: #ff9c87;
$bs-dark-red-color: #ab2105;
$bs-off-red-color: #984545;
$bs-black-color: #222222;
$bs-turquoise-color: #b1e7e2;
$bs-ocean-color: #00504b;
$bs-salmon-color: #f28872;
$bs-active-color: #31bf5d;
$bs-inactive-color: #737680;
$bs-green-color: #63d58f;
$bs-light-green-color: #91ffcc;
$bs-dark-green-color: #048b4d;
$bs-yellow-color: #ffd74b;
$bs-yellow-color-80: #ffd74bcc;
$bs-light-yellow-color: #ffeebc;
$bs-dark-yellow-color: #b58c10;

$bs-primary-color: $bs-primary-blue-color;
$bs-secondary-color: $bs-white-color;
$bs-primary-text-color: $bs-black-color;
$bs-light-text-color: $bs-white-color;

// Nav Items
$nav-background-color: $bs-primary-blue-color;
$nav-hover-color: $bs-light-blue-color;
$nav-active-text-color: $bs-white-color;
$nav-hover-text-color: #000000;
$nav-active-background-color: $bs-dark-blue-color;
$nav-inactive-text-color: #374151;
$nav-notification-pill-color: $bs-red-color;

// Button Items
$button-white-text-color: $bs-white-color;
$button-black-text-color: $bs-black-color;
$button-preferred-hover-color: $bs-hover-blue-color;
$button-neutral-color: $bs-default-gray-color;
$button-neutral-hover-color: $bs-hover-gray-color;
$button-dark-neutral-color: $bs-dark-gray-color;
$button-dark-neutral-hover-color: $bs-extra-dark-gray-color;
$button-outlined-hover-color: $bs-default-gray-color;
$button-border-color: $bs-hover-gray-color;
$button-text-color: $bs-white-color;
$button-text-hover-color: $bs-off-white-color;

// Label Items
$label-positive-color: $bs-turquoise-color;
$label-positive-text-color: $bs-ocean-color;
$label-neutral-color: $bs-hover-gray-color;
$label-neutral-text-color: $bs-black-color;
$label-negative-color: $bs-salmon-color;
$label-negative-text-color: $bs-white-color;

// Tabs
$bs-material: (
  50: #e3f2fd,
  100: #bbdefb,
  200: #90caf9,
  300: #64b5f6,
  400: #42a5f5,
  500: $bs-primary-color,
  600: #1e88e5,
  700: #1976d2,
  800: #1565c0,
  900: #0d47a1,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (
    50: $bs-primary-text-color,
    100: $bs-primary-text-color,
    200: $bs-primary-text-color,
    300: $bs-primary-text-color,
    400: $bs-primary-text-color,
    500: $bs-light-text-color,
    600: $bs-light-text-color,
    700: $bs-light-text-color,
    800: $bs-light-text-color,
    900: $bs-light-text-color,
    A100: $bs-primary-text-color,
    A200: $bs-light-text-color,
    A400: $bs-light-text-color,
    A700: $bs-light-text-color
  )
);

// Drop Down
$dropdown-base-color: $bs-default-gray-color;
$dropdown-hover-color: $bs-white-color;
$dropdown-text-color: $bs-black-color;
$dropdown-border-color: $bs-black-color;
$dropdown-hover-color: $bs-white-color;
$dropdown-hover-border-color: $bs-black-color;
$dropdown-hover-text-color: $bs-black-color;

// Pills
$pill-text-color: $bs-black-color;
$pill-text-active-color: $bs-white-color;
$pill-default-color: $bs-default-gray-color;
$pill-hover-color: $bs-hover-gray-color;
$pill-active-color: $bs-primary-blue-color;

// Checkboxes
$checkbox-base-color: $bs-default-gray-color;
$checkbox-hover-color: $bs-hover-gray-color;
$checkbox-checkmark-color: $bs-white-color;
$checkbox-checked-color: $bs-primary-color;

// Switches
$switch-base-color: $bs-extra-light-grey-color;
$switch-hover-color: $bs-hover-gray-color;
$switch-activated-color: $bs-primary-color;

// Radio
$radio-base-color: $bs-default-gray-color;
$radio-hover-color: $bs-hover-gray-color;
$radio-activated-color: $bs-primary-color;

// Toggle
$toggle-base-color: $bs-default-gray-color;
$toggle-hover-color: $bs-hover-gray-color;
$toggle-activated-color: $bs-primary-color;

// Media Upload
$media-upload-border: $bs-hover-gray-color;
$media-upload-background: $bs-off-white-color;
$media-upload-blue-color: #388efc;
$media-upload-grey-color: $bs-extra-dark-gray-color;
$media-name-color: #a2a2a2;
$media-size-color: #a2a2a2;
$media-error-color: $bs-red-color;

// Lists
$list-background-color: #fdfdfd;
$list-border-color: #707070;
$list-hover-color: $bs-off-white-color;
$list-active-color: #deecff;

// Cards
$card-base-color: #9fa2ad;
$card-banner-color: $bs-default-gray-color;
$card-hover-color: $bs-hover-gray-color;
$card-title-color: $bs-extra-dark-gray-color;
$card-text-color: $bs-black-color;

// Toastr
$toastr-success-color: $bs-green-color;
$toastr-info-color: $bs-primary-color;
$toastr-warning-color: $bs-yellow-color;
$toastr-error-color: $bs-salmon-color;

// Loading
$progress-bar-background-color: $bs-off-white-color;
$progress-bar-progress-color: $bs-primary-blue-color;
$progress-bar-error-color: $bs-red-color;
$progress-bar-success-color: $bs-green-color;

// Textfield
$textfield-fill-color: $bs-default-gray-color;
$textfield-focus-border-color: $bs-black-color;
$textfield-focus-fill-color: $bs-white-color;
$textfield-error-red-color: $bs-red-color;

// Datatable
$bs-table-gray: #c8ced3;
$datatable-hover-background: $bs-light-grey-color;
$datatable-nested-row-background: $bs-extra-light-grey-color;
$datatable-nested-row-border: $bs-primary-blue-color;
$datatable-footer-text: $bs-extra-dark-gray-color;
$datatable-pagination-grey: $bs-default-gray-color;
$datatable-pagination-hover: $bs-hover-gray-color;
$datatable-pagination-selected-color: $bs-primary-blue-color;
$datatable-pagination-active-text: $bs-white-color;
$datatable-pagination-disabled: $bs-off-white-color;

// Scrollbar
$scrollbar-bg-color: $bs-white-color;
$scrollbar-color: $bs-light-blue-color;
