// Poppins

$poppins-thin: '/assets/fonts/Poppins/Poppins-Thin.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-thin) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 100;
}

$poppins-thin-italic: '/assets/fonts/Poppins/Poppins-ThinItalic.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-thin-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 100;
}

$poppins-extra-light: '/assets/fonts/Poppins/Poppins-ExtraLight.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-extra-light) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 200;
}

$poppins-extra-light-italic: '/assets/fonts/Poppins/Poppins-ExtraLightItalic.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-extra-light-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 200;
}

$poppins-light: '/assets/fonts/Poppins/Poppins-Light.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-light) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

$poppins-light-italic: '/assets/fonts/Poppins/Poppins-LightItalic.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-light-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 300;
}

$poppins-regular: '/assets/fonts/Poppins/Poppins-Regular.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-regular) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

$poppins-regular-italic: '/assets/fonts/Poppins/Poppins-Italic.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-regular-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

$poppins-medium: '/assets/fonts/Poppins/Poppins-Medium.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-medium) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

$poppins-medium-italic: '/assets/fonts/Poppins/Poppins-MediumItalic.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-medium-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 500;
}

$poppins-semibold: '/assets/fonts/Poppins/Poppins-SemiBold.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-semibold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

$poppins-semibold-italic: '/assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-semibold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 600;
}

$poppins-bold: '/assets/fonts/Poppins/Poppins-Bold.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-bold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

$poppins-bold-italic: '/assets/fonts/Poppins/Poppins-BoldItalic.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-bold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

$poppins-extra-bold: '/assets/fonts/Poppins/Poppins-ExtraBold.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-extra-bold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 800;
}

$poppins-extra-bold-italic: '/assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-extra-bold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 800;
}

$poppins-black: '/assets/fonts/Poppins/Poppins-Black.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-black) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 900;
}

$poppins-black-italic: '/assets/fonts/Poppins/Poppins-BlackItalic.ttf';
@font-face {
  font-family: 'Poppins';
  src: url($poppins-black-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 900;
}
