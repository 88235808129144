// If you want to override variables do it here
@import 'variables';
@import 'scaling';

// bootstrap
@import 'bootstrap/dist/css/bootstrap.min.css';

// Spinkit
@import 'spinkit/scss/spinkit.scss';

// Import Font
@import 'font';

// Import toast style
@import 'ngx-toastr/toastr';

// If you want to add something do it here - must be below all other imported styles
@import 'buttons';
@import 'custom';
@import 'flex';
@import 'stylesheet';
@import 'material';
@import 'reactive-form';
@import 'search-bar';
@import 'pagination';

app-default-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-body {
  background-color: $bs-background-color;
}

.app-body .main {
  flex: 1;
  min-width: 0;
  min-height: 100vh;
}

body {
  overflow-y: scroll;
  -ms-overflow-style: none;
  overflow-x: hidden;
}

hr {
  background-color: $bs-light-grey-color;
  border: none;
  height: 1px;
}

.main-content {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  overflow: scroll;
  height: 100%;
}

.no-margin {
  margin: 0;
}

.auto-margin {
  margin: auto;
}

// padding and margins
.no-padding {
  padding: 0;
}

.pt-8px {
  padding-top: 0.5rem;
}

.pb-8px {
  padding-bottom: 0.5rem;
}

.pb-48px {
  padding-bottom: 3rem;
}

.pb-64px {
  padding-bottom: 4rem;
}

.mt-none {
  margin-top: 0;
}

.mt-8px {
  margin-top: 0.5rem;
}

.mt-10px {
  margin-top: 0.625rem;
}

.mt-14px {
  margin-top: 0.875rem;
}

.mt-16px {
  margin-top: 1rem;
}

.mt-20px {
  margin-top: 1.25rem;
}

.mt-24px {
  margin-top: 1.5rem;
}

.mt-28px {
  margin-top: 1.75rem;
}

.mt-48px {
  margin-top: 3rem;
}

.mt-64px {
  margin-top: 4rem;
}

.lh-80p {
  line-height: 80%;
}

// Align
.align-self-center {
  align-self: center;
}

// Float
.float-left {
  float: left;
}

.float-right {
  float: right;
}

.no-padding {
  padding: 0;
}

.pt-8px {
  padding-top: 0.5rem;
}

.pt-16px {
  padding-top: 1rem;
}

.pt-24px {
  padding-top: 1.5rem;
}

.pt-40px {
  padding-top: 2.5rem;
}

.pt-48px {
  padding-top: 3rem;
}

.pb-8px {
  padding-bottom: 0.5rem;
}

.pb-16px {
  padding-bottom: 1rem;
}

.pb-24px {
  padding-bottom: 1.5rem;
}

.mt-none {
  margin-top: 0;
}

.mt-2px {
  margin-top: 0.125rem;
}

.mt-4px {
  margin-top: 0.25rem;
}

.mt-6px {
  margin-top: 0.375rem;
}

.mt-8px {
  margin-top: 0.5rem;
}

.mt-10px {
  margin-top: 0.625rem;
}

.mt-12px {
  margin-top: 0.75rem;
}

.mt-14px {
  margin-top: 0.875rem;
}

.mt-16px {
  margin-top: 1rem;
}

.mt-20px {
  margin-top: 1.25rem;
}

.mt-24px {
  margin-top: 1.5rem;
}

.mt-28px {
  margin-top: 1.75rem;
}

.mt-32px {
  margin-top: 2rem;
}

.mt-40px {
  margin-top: 2.5rem;
}

.mt-48px {
  margin-top: 3rem;
}

.ml-8px {
  margin-left: 0.5rem;
}

.ml-12px {
  margin-left: 0.75rem;
}

.ml-16px {
  margin-left: 1rem;
}

.ml-24px {
  margin-left: 1.5rem;
}

.ml-16px-force {
  margin-left: 1rem !important;
}

.mr-6px {
  margin-right: 0.375rem;
}

.mr-10px {
  margin-right: 0.625rem;
}

.mr-16px {
  margin-right: 1rem;
}

.mr-24px {
  margin-right: 1.5rem;
}

.mb-none {
  margin-bottom: 0;
}

.mb-8px {
  margin-bottom: 0.5rem;
}

.mb-16px {
  margin-bottom: 1rem;
}

.mb-20px {
  margin-bottom: 1.25rem;
}

.mb-24px {
  margin-bottom: 1.5rem;
}

.mb-32px {
  margin-bottom: 2rem;
}

.mb-48px {
  margin-bottom: 3rem;
}

.mb-64px {
  margin-bottom: 4rem;
}

.mb-96px {
  margin-bottom: 6rem;
}

.lh-80p {
  line-height: 80%;
}

.full-centered-text {
  text-align: center;
  width: 100%;
}

// Align
.align-self-flex-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

// Float
.float-left {
  float: left;
}

.float-right {
  float: right;
}

// Sizing
.h100p {
  height: 100%;
}

.w100p {
  width: 100%;
}

// Block
.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.modal-content {
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  border-radius: 10px !important;
  border: unset;
  overflow: hidden;
}

.blurred-background {
  backdrop-filter: blur(2px);
}

// Toastr

.toast-container .bs-toast.ngx-toastr.toast-success {
  background-color: $toastr-success-color;
}

.toast-container .bs-toast.ngx-toastr.toast-info {
  background-color: $toastr-info-color;
}

.toast-container .bs-toast.ngx-toastr.toast-warning {
  background-color: $toastr-warning-color;
}

.toast-container .bs-toast.ngx-toastr.toast-error {
  background-color: $toastr-error-color;
}

// Text wrapping

.ellipsis-nowrap-text {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.live-view-modal-landscape .modal-xl {
  max-width: 95vw;
  max-height: 90vh;
}

.live-view-modal-portrait .modal-xl {
  max-width: 65vw;
  max-height: 95vh;
}

.edit-variant-modal .modal-lg {
  max-width: fit-content;
}

// tooltip styling needs to be in the global stylesheet
.tooltip {
  position: relative;
  width: 12rem;
  font-size: 0.9rem;
  z-index: 1056 !important;
}

.show.tooltip {
  opacity: 1;
  z-index: 1056 !important;
}

.cdk-overlay-container {
  z-index: 1052;
}

.tooltip.tooltip-top,
.tooltip.tooltip-bottom,
.tooltip.tooltip-left,
.tooltip.tooltip-right {
  z-index: 100000;
}

// Animating

.animating {
  animation: 0.75s flicker-white-color infinite alternate;
}

.dark-animation.animating {
  animation: 0.75s flicker-dark-color infinite alternate;
}

@keyframes flicker-white-color {
  0% {
    background-color: rgba(255, 255, 255, 0.1);
  }
  10% {
    background-color: rgba(255, 255, 255, 0.2);
  }
  20% {
    background-color: rgba(255, 255, 255, 0.3);
  }
  30% {
    background-color: rgba(255, 255, 255, 0.4);
  }
  40% {
    background-color: rgba(255, 255, 255, 0.5);
  }
  50% {
    background-color: rgba(255, 255, 255, 0.6);
  }
  60% {
    background-color: rgba(255, 255, 255, 0.5);
  }
  70% {
    background-color: rgba(255, 255, 255, 0.4);
  }
  80% {
    background-color: rgba(255, 255, 255, 0.3);
  }
  90% {
    background-color: rgba(255, 255, 255, 0.2);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

@keyframes flicker-dark-color {
  0% {
    background-color: rgba(200, 200, 200, 0.1);
  }
  10% {
    background-color: rgba(200, 200, 200, 0.2);
  }
  20% {
    background-color: rgba(200, 200, 200, 0.3);
  }
  30% {
    background-color: rgba(200, 200, 200, 0.4);
  }
  40% {
    background-color: rgba(200, 200, 200, 0.5);
  }
  50% {
    background-color: rgba(200, 200, 200, 0.6);
  }
  60% {
    background-color: rgba(200, 200, 200, 0.5);
  }
  70% {
    background-color: rgba(200, 200, 200, 0.4);
  }
  80% {
    background-color: rgba(200, 200, 200, 0.3);
  }
  90% {
    background-color: rgba(200, 200, 200, 0.2);
  }
  100% {
    background-color: rgba(200, 200, 200, 0.1);
  }
}
