@import 'variables';

:root {
  // all
  --button-font-family: poppins;
  --button-font-weight: 600;
  --button-font-style: normal;
  // info text
  --button-info-font-family: poppins;
  --button-info-font-weight: 600;
  --button-info-font-style: normal;
  --button-info-font-size: 0.75rem;
  // defaults
  --button-default-text-color: #{$button-text-color};
  --button-default-background-color: #{$bs-black-color};
  --button-default-hover-color: #{$button-text-hover-color};
  --button-default-padding: 0.75rem 1rem;
  --button-default-border-radius: 0.625rem;
  --button-default-height: 2.75rem;
  --button-default-font-size: 0.875rem;
  // button rounded
  --button-rounded-text-color: #{$bs-white-color};
  --button-rounded-background-color: #{$bs-dark-gray-color};
  --button-rounded-hover-color: #{$bs-extra-dark-gray-color};
  --button-rounded-disabled-background-color: #{$bs-default-gray-color};
  // clear
  --button-clear-text-color: #{$bs-black-color};
  --button-clear-font-family: poppins;
  --button-clear-font-weight: 500;
  --button-clear-font-style: normal;
  // destructive
  --button-destructive-text-color: #{$bs-red-color};
  --button-destructive-background-color: #{$bs-white-color};
  --button-destructive-disabled-background-color: #{$bs-red-color};
  // neutral
  --button-neutral-background-color: #{$button-neutral-color};
  --button-neutral-text-color: #{$button-black-text-color};
  --button-neutral-hover-background-color: #{$button-neutral-hover-color};
  --button-neutral-disabled-background-color: #{$button-neutral-color};
  // outlined
  --button-outlined-background-color: #{$bs-white-color};
  --button-outlined-text-color: #{$button-black-text-color};
  --button-outlined-hover-background-color: #{$button-outlined-hover-color};
  // primary
  --button-primary-text-color: #{$bs-white-color};
  --button-primary-background-color: #{$bs-primary-blue-color};
  --button-primary-hover-color: #{$button-preferred-hover-color};
  --button-primary-disabled-background-color: #{$bs-primary-blue-color};
  // text
  --button-text-color: #{$button-black-text-color};
  --button-text-background-color: #{$button-text-color};
  --button-text-hover-color: #{$button-text-hover-color};
}
