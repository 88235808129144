@use 'variables' as v;

:root {
  // reactive-pagination
  --reactive-pagination-container-margin-top: 1rem;
  --reactive-pagination-button-background-color: #{v.$bs-default-gray-color};
  --reactive-pagination-button-text-color: #{v.$bs-black-color};
  --reactive-pagination-button-hover-background-color: #{v.$bs-hover-gray-color};
  --reactive-pagination-button-hover-text-color: #{v.$bs-black-color};
  --reactive-pagination-button-active-background-color: #{v.$bs-primary-blue-color};
  --reactive-pagination-button-active-text-color: #{v.$bs-white-color};
  --reactive-pagination-button-disabled-background-color: #{v.$bs-off-white-color};
  --reactive-pagination-button-disabled-text-color: #{v.$bs-hover-gray-color};
  --reactive-pagination-results-text-font-family: poppins;
  --reactive-pagination-results-text-font-weight: 400;
  --reactive-pagination-results-text-font-style: normal;
  --reactive-pagination-results-text-font-size: 1rem;
  --reactive-pagination-results-text-text-color: #{v.$bs-extra-dark-gray-color};
}
