$primary-font-color: #222222;
$primary-background-color: #ffffff;
/*
 * Order matters here.
 * The rem unit scales relative to the main html font-size.
 * 16px is the standard base size.
 */
html {
  color: $primary-font-color;
  background-color: $primary-background-color;
  font-size: 16px;
}

// 8K landscape scaling
@media only screen and (max-width: 7680px) {
  html {
    font-size: 80px;
  }
}

// 4k landscape scaling
@media only screen and (max-width: 3840px) {
  html {
    font-size: 40px;
  }
}

// 1440p landscape scaling
@media only screen and (max-width: 2560px) {
  html {
    font-size: 22px;
  }
}

// 1080p landscape scaling
@media only screen and (max-width: 1920px) {
  html {
    font-size: 16px;
  }
}

// 720p landscape scaling
@media only screen and (max-width: 1280px) {
  html {
    font-size: 13px;
  }
}

// Amazon Scaling
@media only screen and (width: 960px) and (height: 540px) {
  html {
    font-size: 8px;
  }
}
