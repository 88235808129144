.flex-container {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-inline {
  display: inline-flex;
}

.flex-wrap-content {
  flex-wrap: wrap;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.flex-content-end {
  justify-content: flex-end;
}

.flex-content-start {
  justify-content: start;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-align-center {
  align-items: center;
}

.flex-fill {
  flex: 1;
}

.flex-take-up-remain-space {
  flex: 1 1 0;
}

.align-self-center {
  align-self: center;
}

.align-self-flex-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.gap-1px {
  gap: 0.0625rem;
}

.gap-2px {
  gap: 0.125rem;
}

.gap-3px {
  gap: 0.1875rem;
}

.gap-4px {
  gap: 0.25rem;
}

.gap-5px {
  gap: 0.3125rem;
}

.gap-6px {
  gap: 0.375rem;
}

.gap-7px {
  gap: 0.4375rem;
}

.gap-8px {
  gap: 0.5rem;
}

.gap-9px {
  gap: 0.5625rem;
}

.gap-10px {
  gap: 0.625rem;
}

.gap-11px {
  gap: 0.6875rem;
}

.gap-12px {
  gap: 0.75rem;
}

.gap-13px {
  gap: 0.8125rem;
}

.gap-14px {
  gap: 0.875rem;
}

.gap-15px {
  gap: 0.9375rem;
}

.gap-16px {
  gap: 1rem;
}

.gap-17px {
  gap: 1.0625rem;
}

.gap-18px {
  gap: 1.125rem;
}

.gap-19px {
  gap: 1.1875rem;
}

.gap-20px {
  gap: 1.25rem;
}

.gap-21px {
  gap: 1.3125rem;
}

.gap-22px {
  gap: 1.375rem;
}

.gap-23px {
  gap: 1.4375rem;
}

.gap-24px {
  gap: 1.5rem;
}

.gap-25px {
  gap: 1.5625rem;
}

.gap-26px {
  gap: 1.625rem;
}

.gap-27px {
  gap: 1.6875rem;
}

.gap-28px {
  gap: 1.75rem;
}

.gap-29px {
  gap: 1.8125rem;
}

.gap-30px {
  gap: 1.875rem;
}

.gap-31px {
  gap: 1.9375rem;
}

.gap-32px {
  gap: 2rem;
}

.gap-33px {
  gap: 2.0625rem;
}

.gap-34px {
  gap: 2.125rem;
}

.gap-35px {
  gap: 2.1875rem;
}

.gap-36px {
  gap: 2.25rem;
}

.gap-37px {
  gap: 2.3125rem;
}

.gap-38px {
  gap: 2.375rem;
}

.gap-39px {
  gap: 2.4375rem;
}

.gap-40px {
  gap: 2.5rem;
}

.gap-41px {
  gap: 2.5625rem;
}

.gap-42px {
  gap: 2.625rem;
}

.gap-43px {
  gap: 2.6875rem;
}

.gap-44px {
  gap: 2.75rem;
}

.gap-45px {
  gap: 2.8125rem;
}

.gap-46px {
  gap: 2.875rem;
}

.gap-47px {
  gap: 2.9375rem;
}

.gap-48px {
  gap: 3rem;
}
