@import 'font';
@import 'variables';

:root {
  // colors
  --reactive-form-primary-color: #{$bs-primary-blue-color};
  --reactive-form-black: #{$bs-black-color};
  --reactive-form-white: #{$bs-white-color};
  --reactive-form-primary-text-color: var(--reactive-form-black);
  --reactive-form-required-error-color: #{$bs-red-color};
  --reactive-form-required-star-color: var(--reactive-form-required-error-color);
  --reactive-form-default-gray-color: #{$bs-default-gray-color};
  --reactive-form-extra-light-gray-color: #{$bs-extra-light-grey-color};
  --reactive-form-hover-gray-color: #{$bs-hover-gray-color};
  --reactive-form-placeholder-text-color: #{$bs-secondary-dark-gray-color};
  // fonts
  --reactive-form-font-family: poppins;
  --reactive-form-font-regular-weight: 400;
  --reactive-form-font-medium-weight: 500;
  --reactive-form-font-semi-bold-weight: 600;
  // input fields
  --reactive-form-input-width: 100%;
  --reactive-form-input-height: 2.5rem;
  --reactive-form-input-font-size: 0.875rem;
  --reactive-form-input-border-radius: 0.625rem;
  --reactive-form-input-border: 1px solid var(--reactive-form-default-gray-color);
  --reactive-form-input-padding: 0.8125rem 0.5rem;
  --reactive-form-input-line-height: 1rem;
  --reactive-form-input-background-color: var(--reactive-form-default-gray-color);
  --reactive-form-input-color: var(--reactive-form-primary-text-color);
  // input field labels
  --reactive-form-label-font-size: 0.8rem;
  --reactive-form-label-line-height: 1;
  --reactive-form-label-height: 1.25rem;
  --reactive-form-label-color: var(--reactive-form-primary-text-color);
  // dropdowns
  --reactive-form-drop-down-background-color: var(--reactive-form-default-gray-color);
  --reactive-form-drop-down-background-image: url('data:image/svg+xml;base64,PHN2ZyBkYXRhLW5hbWU9IkxheWVyIDEiIGlkPSJMYXllcl8xIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGRlZnM+CiAgICA8c3R5bGU+LmNscy0xIHsKICAgICAgZmlsbDogI2ZmZjsKICAgICAgb3BhY2l0eTogMDsKICAgIH0KCiAgICAuY2xzLTIgewogICAgICBmaWxsOiAjMzMzMzMzOwogICAgfTwvc3R5bGU+CiAgPC9kZWZzPgogIDxyZWN0IGNsYXNzPSJjbHMtMSIgaGVpZ2h0PSIyNCIgd2lkdGg9IjI0Ii8+CiAgPHBhdGggY2xhc3M9ImNscy0yIgogICAgICAgIGQ9Ik0xNywxMC4zMmEuNTMuNTMsMCwwLDAtLjQ4LS4zMmgtOWEuNTMuNTMsMCwwLDAtLjQ4LjMyLjU0LjU0LDAsMCwwLC4xMS41OGw0LjQ4LDQuNDdhLjUzLjUzLDAsMCwwLC43NCwwbDQuNDgtNC40N0EuNTQuNTQsMCwwLDAsMTcsMTAuMzJaIi8+Cjwvc3ZnPgo=');
  --reactive-form-drop-down-background-position-x: calc(100% - 0.7rem);
  --reactive-form-drop-down-background-position-y: center;
  --reactive-form-drop-down-background-repeat: no-repeat;
  --reactive-form-drop-down-background-size: 1.5rem;
  --reactive-form-drop-down-border: none;
  --reactive-form-drop-down-border-radius: 0.625rem;
  --reactive-form-drop-down-color: var(--reactive-form-black);
  --reactive-form-drop-down-font-size: 0.875rem;
  --reactive-form-drop-down-height: 2.5rem;
  --reactive-form-drop-down-line-height: 1.125rem;
  --reactive-form-drop-down-overflow-x: hidden;
  --reactive-form-drop-down-padding: 0 2rem 0 0.875rem;
  --reactive-form-drop-down-text-overflow: ellipsis;
  --reactive-form-drop-down-width: 100%;
  --reactive-form-drop-down-white-space: nowrap;
  --reactive-form-drop-down-focus-outline: none;
  --reactive-form-drop-down-hover-background-color: var(--reactive-form-white);
  --reactive-form-drop-down-hover-border: 1px solid var(--reactive-form-black);
  --reactive-form-drop-down-hover-color: var(--reactive-form-black);
  // pill drop down
  --reactive-form-pill-drop-down-width: 100%;
  --reactive-form-pill-drop-down-search-min-width: 7rem;
  --reactive-form-pill-drop-down-padding: 0 0 0 0.875rem;
  --reactive-form-pill-drop-down-min-height: 2.5rem;
  --reactive-form-pill-drop-down-border-radius: 0.625rem;
  --reactive-form-pill-drop-down-background-color: var(--reactive-form-default-gray-color);
  --reactive-form-pill-drop-down-focus-background-color: var(--reactive-form-white);
  --reactive-form-pill-drop-down-color: var(--reactive-form-black);
  --reactive-form-pill-drop-down-font-size: 0.875rem;
  --reactive-form-pill-drop-down-line-height: 1.125rem;
  --reactive-form-pill-drop-down-border: none;
  --reactive-form-pill-drop-down-white-space: nowrap;
  --reactive-form-pill-drop-down-overflow-x: hidden;
  --reactive-form-pill-drop-down-text-overflow: ellipsis;
  --reactive-form-pill-drop-down-focus-outline: 0.1rem solid;
  --reactive-form-pill-drop-down-hover-background-color: var(--reactive-form-white);
  --reactive-form-pill-drop-down-hover-border: 1px solid var(--reactive-form-black);
  --reactive-form-pill-drop-down-hover-color: var(--reactive-form-black);
  --reactive-form-pill-drop-down-pill-column-gap: 0.5rem;
  --reactive-form-pill-drop-down-row-gap-between-pills: 0.25rem;
  --reactive-form-pill-drop-down-padding-around-pills-and-search: 0.45rem 0;
  --reactive-form-pill-drop-down-selection-button-hover-color: var(--reactive-form-hover-gray-color);
  --reactive-form-drop-down-pill-background-color: var(--reactive-form-black);
  --reactive-form-drop-down-pill-hover-background-color: #4f4f4f;
  --reactive-form-drop-down-pill-text-color: var(--reactive-form-white);
  --ractive-form-drop-down-pill-hover-text-color: var(--reactive-form-white);
  --reactive-form-drop-down-pill-font-size: 1rem;
  --reactive-form-drop-down-pill-line-height: 1;
  --reactive-form-drop-down-pill-border-radius: 0.25rem;
  --reactive-form-drop-down-pill-border: none;
  --reactive-form-drop-down-pill-padding: 0.25rem 0.5rem;
  --reactive-form-drop-down-pill-gap-between-title-and-close-icon: 0.25rem;
  // list for pill drop down
  --reactive-form-list-padding: 1rem 0.5rem;
  --reactive-form-list-max-height: 18rem;
  --reactive-form-list-item-height: unset;
  --reactive-form-list-item-padding: 0.75rem 0.5rem;
  --reactive-form-list-item-border-radius: 0.25rem;
  --reactive-form-list-item-hover-color: var(--reactive-form-hover-gray-color);
  // pill sortable list
  --pill-sortable-list-border-color: #f2f2f2;
  --pill-sortable-list-background-color: #ffffff;
}
