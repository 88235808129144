@use 'variables' as v;

:root {
  // reactive-search-bar-label
  --reactive-search-bar-label-font-family: poppins;
  --reactive-search-bar-label-font-weight: 500;
  --reactive-search-bar-label-font-style: normal;
  --reactive-search-bar-label-font-size: 0.8rem;
  --reactive-search-bar-label-text-color: #{v.$bs-black-color};
  --reactive-search-bar-label-margin-bottom: 0.13rem;
  // reactive-search-bar
  --reactive-search-bar-text-font-family: poppins;
  --reactive-search-bar-text-font-weight: 400;
  --reactive-search-bar-text-font-style: normal;
  --reactive-search-bar-text-font-size: 0.875rem;
  --reactive-search-bar-text-text-color: #{v.$bs-black-color};
  --reactive-search-bar-height: 2.5rem;
  --reactive-search-bar-border-radius: 0.625rem;
  --reactive-search-bar-background-color: #{v.$bs-default-gray-color};
  --reactive-search-bar-border: 1px solid #{v.$bs-default-gray-color};
  --reactive-search-bar-padding: 0 0.5rem;
  --reactive-search-bar-read-only-background-color: #{v.$bs-default-gray-color};
  --reactive-search-bar-read-only-focus-background-color: #{v.$bs-default-gray-color};
  --reactive-search-bar-focus-text-color: #{v.$bs-black-color};
  --reactive-search-bar-focus-border: 1px solid #{v.$bs-black-color};
  --reactive-search-bar-focus-background-color: #{v.$bs-default-gray-color};
  --reactive-search-bar-focus-box-shadow: 0 0 1px 1px #{v.$bs-extra-light-grey-color};
  // reactive-search-bar-clear
  --reactive-search-bar-clear-font-family: poppins;
  --reactive-search-bar-clear-font-weight: 500;
  --reactive-search-bar-clear-font-style: normal;
  --reactive-search-bar-clear-font-size: 0.625rem;
  --reactive-search-bar-clear-text-color: #{v.$bs-black-color};
}
