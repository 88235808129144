@use 'font-family';

@mixin bs-zero-letter-spacing {
  letter-spacing: 0;
}

// Default BudSense Font

@mixin poppins-light {
  font-family: poppins, sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin poppins-italic {
  font-family: poppins, sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin poppins-regular {
  font-family: poppins, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin poppins-medium {
  font-family: poppins, sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin poppins-medium-italic {
  font-family: poppins, sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin poppins-semi-bold {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin poppins-semi-bold-italic {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin poppins-bold-italic {
  font-family: poppins, sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin poppins-bold {
  font-family: poppins, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin global-line-height {
  line-height: 1;
}

// Default BudSense Font

@mixin bs-italic {
  @include poppins-italic;
}

.bs-italic {
  @include bs-italic;
}

@mixin bs-regular {
  @include poppins-regular;
}

.bs-regular {
  @include bs-regular;
}

@mixin bs-medium {
  @include poppins-medium;
}

.bs-medium {
  @include bs-medium;
}

.bs-medium-italic {
  @include poppins-medium-italic;
}

@mixin bs-bold-italic {
  @include poppins-bold-italic;
}

.bs-bold-italic {
  @include bs-bold-italic;
}

@mixin bs-semi-bold {
  @include poppins-semi-bold;
}

.bs-semi-bold {
  @include bs-semi-bold;
}

@mixin bs-semi-bold-italic {
  @include poppins-semi-bold-italic;
}

.bs-semi-bold-italic {
  @include bs-semi-bold-italic;
}

@mixin bs-bold {
  @include poppins-bold;
}

.bs-bold {
  @include bs-bold;
}

// sizes

@mixin f1px {
  font-size: 0.0625rem;
}
@mixin f2px {
  font-size: 0.125rem;
}
@mixin f3px {
  font-size: 0.1875rem;
}
@mixin f4px {
  font-size: 0.25rem;
}
@mixin f5px {
  font-size: 0.3125rem;
}
@mixin f6px {
  font-size: 0.375rem;
}
@mixin f7px {
  font-size: 0.4375rem;
}
@mixin f8px {
  font-size: 0.5rem;
}
@mixin f9px {
  font-size: 0.5625rem;
}
@mixin f10px {
  font-size: 0.625rem;
}
@mixin f11px {
  font-size: 0.6875rem;
}
@mixin f12px {
  font-size: 0.75rem;
}
@mixin f13px {
  font-size: 0.8125rem;
}
@mixin f14px {
  font-size: 0.875rem;
}
@mixin f15px {
  font-size: 0.9375rem;
}
@mixin f16px {
  font-size: 1rem;
}
@mixin f17px {
  font-size: 1.0625rem;
}
@mixin f18px {
  font-size: 1.125rem;
}
@mixin f19px {
  font-size: 1.1875rem;
}
@mixin f20px {
  font-size: 1.25rem;
}
@mixin f21px {
  font-size: 1.3125rem;
}
@mixin f22px {
  font-size: 1.375rem;
}
@mixin f23px {
  font-size: 1.4375rem;
}
@mixin f24px {
  font-size: 1.5rem;
}
@mixin f25px {
  font-size: 1.5625rem;
}
@mixin f26px {
  font-size: 1.625rem;
}
@mixin f27px {
  font-size: 1.6875rem;
}
@mixin f28px {
  font-size: 1.75rem;
}
@mixin f29px {
  font-size: 1.8125rem;
}
@mixin f30px {
  font-size: 1.875rem;
}
@mixin f31px {
  font-size: 1.9375rem;
}
@mixin f32px {
  font-size: 2rem;
}
@mixin f33px {
  font-size: 2.0625rem;
}
@mixin f34px {
  font-size: 2.125rem;
}
@mixin f35px {
  font-size: 2.1875rem;
}
@mixin f36px {
  font-size: 2.25rem;
}
@mixin f37px {
  font-size: 2.3125rem;
}
@mixin f38px {
  font-size: 2.375rem;
}
@mixin f39px {
  font-size: 2.4375rem;
}
@mixin f40px {
  font-size: 2.5rem;
}
@mixin f41px {
  font-size: 2.5625rem;
}
@mixin f42px {
  font-size: 2.625rem;
}
@mixin f43px {
  font-size: 2.6875rem;
}
@mixin f44px {
  font-size: 2.75rem;
}
@mixin f45px {
  font-size: 2.8125rem;
}
@mixin f46px {
  font-size: 2.875rem;
}
@mixin f47px {
  font-size: 2.9375rem;
}
@mixin f48px {
  font-size: 3rem;
}
@mixin f49px {
  font-size: 3.0625rem;
}
@mixin f50px {
  font-size: 3.125rem;
}
@mixin f51px {
  font-size: 3.1875rem;
}
@mixin f52px {
  font-size: 3.25rem;
}
@mixin f53px {
  font-size: 3.3125rem;
}
@mixin f54px {
  font-size: 3.375rem;
}
@mixin f55px {
  font-size: 3.4375rem;
}
@mixin f56px {
  font-size: 3.5rem;
}
@mixin f57px {
  font-size: 3.5625rem;
}
@mixin f58px {
  font-size: 3.625rem;
}
@mixin f59px {
  font-size: 3.6875rem;
}
@mixin f60px {
  font-size: 3.75rem;
}
@mixin f61px {
  font-size: 3.8125rem;
}
@mixin f62px {
  font-size: 3.875rem;
}
@mixin f63px {
  font-size: 3.9375rem;
}
@mixin f64px {
  font-size: 4rem;
}
@mixin f65px {
  font-size: 4.0625rem;
}
@mixin f66px {
  font-size: 4.125rem;
}
@mixin f67px {
  font-size: 4.1875rem;
}
@mixin f68px {
  font-size: 4.25rem;
}
@mixin f69px {
  font-size: 4.3125rem;
}
@mixin f70px {
  font-size: 4.375rem;
}
@mixin f71px {
  font-size: 4.4375rem;
}
@mixin f72px {
  font-size: 4.5rem;
}
@mixin f73px {
  font-size: 4.5625rem;
}
@mixin f74px {
  font-size: 4.625rem;
}
@mixin f75px {
  font-size: 4.6875rem;
}
@mixin f76px {
  font-size: 4.75rem;
}
@mixin f77px {
  font-size: 4.8125rem;
}
@mixin f78px {
  font-size: 4.875rem;
}
@mixin f79px {
  font-size: 4.9375rem;
}
@mixin f80px {
  font-size: 5rem;
}
@mixin f81px {
  font-size: 5.0625rem;
}
@mixin f82px {
  font-size: 5.125rem;
}
@mixin f83px {
  font-size: 5.1875rem;
}
@mixin f84px {
  font-size: 5.25rem;
}
@mixin f85px {
  font-size: 5.3125rem;
}
@mixin f86px {
  font-size: 5.375rem;
}
@mixin f87px {
  font-size: 5.4375rem;
}
@mixin f88px {
  font-size: 5.5rem;
}
@mixin f89px {
  font-size: 5.5625rem;
}
@mixin f90px {
  font-size: 5.625rem;
}
@mixin f91px {
  font-size: 5.6875rem;
}
@mixin f92px {
  font-size: 5.75rem;
}
@mixin f93px {
  font-size: 5.8125rem;
}
@mixin f94px {
  font-size: 5.875rem;
}
@mixin f95px {
  font-size: 5.9375rem;
}
@mixin f96px {
  font-size: 6rem;
}
@mixin f97px {
  font-size: 6.0625rem;
}
@mixin f98px {
  font-size: 6.125rem;
}
@mixin f99px {
  font-size: 6.1875rem;
}
@mixin f100px {
  font-size: 6.25rem;
}
.f12px {
  @include f12px;
}
.f14px {
  @include f14px;
}
.f16px {
  @include f16px;
}
.f18px {
  @include f18px;
}
.f20px {
  @include f20px;
}
.f24px {
  @include f24px;
}
.f32px {
  @include f32px;
}
.f48px {
  @include f48px;
}

// Other

//noinspection CssUnknownProperty
@mixin noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

@mixin show-new-lines {
  white-space: pre-line;
}
